/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { CartState } from '../../types/state-types';
import {
  updateCartTotals,
  updateCheckCartInfo,
  updateCheckForGiftBox,
  updateCurrentCart,
  updateHideEmptyCartPageObjects,
} from './actions';

export const initialState: CartState = {
  cartTotals: null,
  currentCart: null,
  checkForGiftBox: false,
  checkCartInfo: false,
  hideEmptyCartPageObjects: false,
};

export const cartReducer = createReducer(initialState, builder => {
  builder
    .addCase(updateCartTotals, (state, action) => {
      state.cartTotals = action.payload;
    })
    .addCase(updateCurrentCart, (state, action) => {
      state.currentCart = action.payload;
    })
    .addCase(updateCheckForGiftBox, (state, action) => {
      state.checkForGiftBox = action.payload;
    })
    .addCase(updateCheckCartInfo, (state, action) => {
      state.checkCartInfo = action.payload;
    })
    .addCase(updateHideEmptyCartPageObjects, (state, action) => {
      state.hideEmptyCartPageObjects = action.payload;
    });
});
