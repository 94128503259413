export const vars = {
  brandPrimary: '#1e1e1e',
  greyLight: '#f8f7f4',
  greyLightest: '#f8f7f4',
  greyDark: '#d7d7d7',
  greyDarkest: '#cbcbcb',
  greyDisabled: '#f8f7f4',
  greyAppBanner: '#808080',
  greyAppBannerLight: '#bfbfbf',
  greyAppBannerLightest: '#f2f2f2',
  greyAppBannerDark: '#3d3d3d',
  blueLight: '#0c71fd',
  white: '#ffffff',
  black: '#1e1e1e',
  green: '#42ca49',
  greenLightest: '#f5fcf6',
  red: '#b40000',
  redError: '#ff0000',
  redDark: '#b40000',
  defaultBlack: '#000000',
  fontWeightLight: 300,
  slowSpeed: '1s',
  mediumSpeed: '.5s',
  fastSpeed: '.25s',
  imageBaseUrl: 'https://kg-static.s3.amazonaws.com/assets/kurtgeiger',
  tabletBp: 768,
  desktopBp: 1024,
  sizeXS: '.8rem',
  sizeS: '1.2rem',
  sizeM: '1.6rem',
  sizeL: '2rem',
  sizeXL: '2.4rem',
  sizeXXL: '4rem',
};

export const breakpoints = {
  tablet: `${vars.tabletBp}px`,
  desktop: `${vars.desktopBp}px`,
  tabletMax: `${vars.tabletBp - 1}px`,
  desktopMax: `${vars.desktopBp - 1}px`,
};

export const fonts = {
  fontKudryashevHeadline: 'KudryashevHeadline',
  fontShapiroBase55Middle: 'ShapiroBase-55Middle',
  fontShapiroBase63LightHeavy: 'ShapiroBase-63LightHeavy',
  fontFamilyPrimary: 'ProximaNova-Regular',
};

export const devices = {
  smallOnly: `(max-width: ${breakpoints.tabletMax})`,
  medium: `(min-width: ${breakpoints.tablet})`,
  mediumOnly: `(min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktopMax})`,
  large: `(min-width: ${breakpoints.desktop})`,
};

export const buttons = {
  primaryHover: {
    textDecoration: 'underline',
    background: vars.brandPrimary,
    color: vars.white,
    borderColor: vars.brandPrimary,
  },
  secondary: {
    borderColor: vars.brandPrimary,
  },
  tertiary: {
    color: vars.greyDark,
  },
};

export const headerColors = {
  backgroundColor: vars.white,
  mobileMenuBorder: vars.greyLight,
  mobileMenuBackBg: vars.greyLightest,
};

export const footerColors = {
  backgroundColor: vars.white,
  signUpButtonBackgroundColor: vars.black,
  signUpButtonTextColor: vars.white,
  signUpButtonBorderHover: 'none',
  radioButtonBorderColor: vars.greyDark,
};

export const blockTextColors = {
  default: vars.black,
  dark: vars.greyDark,
  light: vars.white,
};

export const blockLetterSpacing = {
  headingL: 'normal',
};

export const blockFonts = {
  titleS: fonts.fontKudryashevHeadline,
  titleM: fonts.fontKudryashevHeadline,
  titleL: fonts.fontKudryashevHeadline,
  subtitle: fonts.fontShapiroBase55Middle,
};

export const blockFontSizes = {
  headingS: '1.9rem',
  headingM: '2.2rem',
  headingL: '2.4rem',
  headingLMobile: '3rem',
  subtitleS: '1.4rem',
  subtitleM: '1.4rem',
  subtitleL: '1.4rem',
  descriptionCtaS: '1.4rem',
  descriptionCtaM: '1.4rem',
  descriptionCtaL: '1.4rem',
};

export const blockTopMargins = {
  heading: '0.5rem',
  description: '1rem',
  cta: '1rem',
};

export const plp = {
  filters: {
    dropdownValue: {
      fontSize: '1.2rem',
    },
    textTransform: 'none',
    minWidth: '17rem,',
    indent: '7rem',
  },
  banner: {
    minHeight: '25rem',
    innerLineHeight: '1.6rem',
  },
  skeleton: {
    startColor: vars.greyDark,
    endColor: vars.greyLightest,
  },
  dropdownTitle: 'h2Style',
  dropdownTitleSvgMargin: '0',
};

export const productsList = {
  container: {
    width: '80%',
  },
};

export const productCard = {
  brandName: 'h4Style',
  name: {
    fontWeight: '400',
    textTransform: 'none',
    lineHeight: '1rem',
  },
  roundelFlag: {
    borderBottom: vars.greyDark,
    color: vars.redDark,
  },
  quickBuy: {
    hoverBackground: vars.greyLightest,
    hoverColor: vars.black,
    activeBackground: vars.brandPrimary,
    disabledBackground: vars.greyDisabled,
    position: '8.6rem',
  },
  textMargin: '0.5rem',
};

export const productPrice = {
  sale: {
    colour: vars.redDark,
  },
  strikethrough: {
    height: '0.05rem',
  },
};

export const pdpContent = {
  top: '13.5rem',
  colourSelector: {
    activeHoverColour: vars.black,
  },
  colourLabel: {
    textTransform: 'none',
    style: 'h4Style',
  },
  sizeButton: {
    hoverActiveDisabledColour: vars.black,
    mobileDisabledColour: vars.greyDark,
  },
  sizeSelected: {
    background: vars.black,
    color: vars.white,
  },
  sliderBar: {
    color: vars.greyDark,
  },
  productName: {
    style: `
      font-size: 1.4rem;
      font-family: ${fonts.fontFamilyPrimary};
      text-transform: capitalize;
      line-height: 2rem;
    `,
    margin: '0.8rem 0 0',
  },
  productBrand: {
    style: 'h2Style',
    styleRedesign: 'h6Style',
    marginTop: '1rem',
    fontFamily: fonts.fontKudryashevHeadline,
  },
  accordionTrigger: {
    textTransform: 'none',
    borderColor: vars.greyDark,
  },
  imageDots: {
    color: vars.brandPrimary,
    width: '1rem',
    fontSize: '1rem',
  },
  sliderImageDots: {
    color: vars.brandPrimary,
    width: '0.85rem',
    marginRight: '0.6rem',
  },
  availableSizesMobile: {
    marginTop: 0,
    style: 'labelStyle',
  },
  colourTitle: {
    marginTop: '1.2rem',
  },
  titles: {
    textTransform: 'none',
  },
  price: {
    margin: '0.9rem 0.5rem 0 0',
  },
  rotationSliderHandle: {
    background: vars.brandPrimary,
  },
  toolTip: {
    background: vars.brandPrimary,
    color: vars.white,
  },
  borderColor: vars.greyDark,
  selectSizeMobile: 'h4Style',
  sizeTypeToggle: 'h4Style',
};

export const search = {
  headings: 'h4Style',
  brandName: 'h4Style',
  productName: 'labelStyle',
};

export const minibag = {
  product: {
    fontSize: '1.2rem',
    borderColor: vars.greyDark,
    name: {
      fontWeight: 400,
      textTransform: 'capitalize',
    },
    text: {
      marginBottom: '0.3rem',
    },
  },
};

export const countryPicker = {
  change: {
    textTransform: 'none',
  },
};

export const header = {
  mobileLogoMaxW: '18rem',
  desktopLogoMaxW: '24rem',
  bannerLink: {
    textDecoration: 'none',
    fontFamily: fonts.fontFamilyPrimary,
  },
};

export const checkout = {
  summaryCaseStyle: 'capitalize',
  summarySubTotalCaseStyle: 'initial',
  summaryContinueShopping: fonts.fontFamilyPrimary,
  summaryTitle: {
    font: fonts.fontShapiroBase55Middle,
    size: '1.4rem',
    margin: '1rem',
  },
  productTitle: {
    font: fonts.fontFamilyPrimary, 
  },
  summarySecureMessage: {
    url: {
      case: 'lowercase',
      font: fonts.fontShapiroBase63LightHeavy,
      size: '1.2rem',
    },
    para: {
      size: '1.3rem',
    }
  },
  summaryNoItemsText: {
    para: 'initial',
    link: {
      case: 'initial',
      fontFamily: fonts.fontFamilyPrimary,
    }
  },
  carousel: {
    font: fonts.fontKudryashevHeadline,
    fontSize: '2.2rem',
    case: 'capitalize',
  },
  button: {
    fontSize: '1.4rem',
    background: buttons.primaryHover.background,
    backgroundHover: buttons.primaryHover.background,
    color: buttons.primaryHover.color,
    colorHover: buttons.primaryHover.color,
  }
};

export const footer = {
  fonts: {
    primary: fonts.fontFamilyPrimary,
  },
  padding: '3rem 5%',
  borderTop: `1px solid ${vars.greyDark};`,
  accordionTriggerFontSize: '1.4rem',
};

export const upsell = {
  tabTrigger: 'h4Style',
  tabTriggerQubit: 'h4Style',
  tabTextTransform: 'uppercase',
  textTransform: 'capitalize',
  borderColor: vars.greyDark,
  fontWeight: 400,
};

export const stories = {
  tabs: {
    transform: 'capitalize',
  },
  storyTitle: 'h3Style',
  storyTitleTransform: 'unset',
  titleLinesColor: vars.greyDark,
  titleLinesWidth: `calc(51% - 15rem)`,
  paddingTop: '12rem',
};

export const celebrity = {
  card: {
    asSeenOnTransform: 'none',
    asSeenOnTitle: 'h2Style',
    celebrity: 'h4Style',
    shoeName: 'labelStyle',
    brandName: 'h4Style',
    color: vars.black,
  },
};

export const storeLocator = {
  fontFamily: fonts.fontFamilyPrimary,
  input: {
    borderColor: vars.greyDark,
  },
  addressList: {
    button: {
      background: vars.greyLight,
    },
    borderColor: vars.greyDark,
  },
  markerColour: vars.black,
  storeDetailsHeader: {
    textTransform: 'none',
  },
  storeTitle: {
    style: 'h2Style',
    textTransform: 'none',
  },
  storeDistance: {
    style: 'labelStyle',
    textTransform: 'none',
  },
  phone: {
    fontWeight: 700,
    textDecoration: 'none',
  },
  backButton: {
    textTransform: 'none',
  },
};

export const popup = {
  textTransform: 'none',
};

export const welcomePopup = {
  color: vars.black,
};

export const wearWithTrigger = {
  background: vars.greyLight,
};

export const filters = {
  radioButton: {
    borderColour: vars.greyDark,
  },
};

export const cmsPages = {
  h1LineHeightFix: '1.4rem',
};

export const linksAnimations = {
  displayType: 'none',
};

export const Variables = {
  vars,
  breakpoints,
  buttons,
  fonts,
  devices,
  headerColors,
  footerColors,
  blockTextColors,
  blockLetterSpacing,
  blockFonts,
  blockFontSizes,
  plp,
  productsList,
  productCard,
  productPrice,
  pdpContent,
  search,
  minibag,
  header,
  footer,
  upsell,
  countryPicker,
  stories,
  celebrity,
  storeLocator,
  popup,
  welcomePopup,
  blockTopMargins,
  wearWithTrigger,
  filters,
  cmsPages,
  linksAnimations,
  checkout
};

export default Variables;
